import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Reactmarkdown from "react-markdown"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderBuero from "../components/header_buero"

const BueroPage = ({ data }) => {
  const qry = data.allStrapiBuero.edges[0].node
  const heroImage = qry.picture.localFile.childImageSharp.fluid

  return (
    <Layout>
      <SEO title="Das Büro" />
      <HeaderBuero />
      <Img fluid={heroImage} className="heroimage" />
      <Reactmarkdown>{qry.content}</Reactmarkdown>
    </Layout>
  )
}

export default BueroPage

export const query = graphql`
  query BueroQuery {
    allStrapiBuero {
      edges {
        node {
          content
          picture {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
